import React from 'react'
import { Accordion, AccordionWrapper } from '../seo-content/seo_content'

import { Helmet } from "react-helmet";
import { withAppContext } from 'src/store/initAppContext';
import DragontigerSEO39 from './DragontigerSEO39';

// const structuredData = (domain) => {
//     const data = {
//         "@context": "https://schema.org",
//         "@type": "BreadcrumbList",
//         "itemListElement":
//             [
//                 {
//                     "@type": "ListItem",
//                     "item": `${domain}`,
//                     "name": "Homepage",
//                     "position": 1
//                 },
//                 {
//                     "@type": "ListItem",
//                     "item": `${domain}/online-casino-games/`,
//                     "name": "Play Online Casino Games",
//                     "position": 2
//                 },
//                 {
//                     "@type": "ListItem",
//                     "item": `${domain}/online-casino-games/dragon-tiger`,
//                     "name": "Dragon Tiger",
//                     "position": 3
//                 }],
//         "name": "Breadcrumbs"
//     }

//     return JSON.stringify(data)
// }

const DragontigerSEO = (props) => {
    const { app: { brandId } } = props;

    let domainURL = 'https://www.yolo247.co';

    if (brandId === 32) {
        domainURL = 'https://www.yolo247.club';
    }
    else if (brandId === 33) {
        domainURL = 'https://www.baaziadda.com';
    }
    else if (brandId === 34) {
        domainURL = 'https://www.betdaily.club';
    }
    else if (brandId === 39) {
        domainURL = 'https://www.foma7.com';
    } else if (brandId === 40) {
        domainURL = 'https://www.fomo7.xyz';
      }

    const domain = (brandId === 31 || brandId === 32) ? 'Yolo247' : brandId === 33 ? 'Baazi Adda' : (brandId === 39 || brandId === 40) ? 'FOMO7':  'Betdaily' ;

    return (
        <>
            {/* <Helmet>
                <script type="application/ld+json">
                    {
                        structuredData(domainURL)
                    }

                </script>
            </Helmet> */}
          {brandId == 33 ? <></> :  
            <AccordionWrapper>
                <Accordion>
                    <input type="checkbox" id="section1" className="accordion__input" />
                    <label htmlFor="section1" className="accordion__label">{(brandId == 39 || brandId == 40) ? <h1>Dragon Tiger Online Casino Game on {domain} Live Casino</h1> : <h1>Play Dragon Tiger Game</h1>}</label>
                    {(brandId == 39 || brandId == 40) ? <DragontigerSEO39 /> : (
                        <div className="accordion__content">
                        <h2> Dragon Tiger Game</h2>

                        <p>Dragon Tiger game is a two-card version of Baccarat. Only two cards are drawn in this game, one for the dragon and the other for the tiger. The players place a bet on the possibility of a higher card, and there are other proposition bets as well.

                            The Dragon Tiger game is quite popular in Asian countries mainly because of its name. The game is so quick that it usually only takes around 25 seconds to finish a round, which includes 15 seconds for placing bets, and five seconds taken by the dealer to reveal the cards.

                        </p><h3> Dragon vs Tiger Game</h3>

                        <p>Dragon vs Tiger Game as the name suggests is all about these two beasts: Dragon & Tiger.

                            This game has a relatively low house edge as the results could either be tiger win, dragon win, or a tie. Although this game looks like baccarat but it has a close resemblance to Casino War.

                        </p><h3> Variations available for Dragon Tiger Online</h3>
                        <p>
                            The Dragon Tiger online is well-known in online casinos across the world. Dragon game has won the hearts of many players because of the winning probabilities.

                            The Live Dragon Tiger game tables are quite popular in online casinos. Game software providers such as Ezugi, Superspade, Supernowa, Spribe, Betgames, Netent are known for their unmatchable variations of the classic <a href={`${domainURL}/online-casino-games`}>casino games</a>. Even though these software developers use the same game rules for the player’s convenience.

                            All the Dragon Tiger game tables are unique, with different designs and graphics that allow the players to choose from diverse experiences.

                            To clarify: there is no chance in the rules, but in the interface, design and betting options.
                        </p>
                        <h2> Tips & Strategies to win</h2>

                        <p>Though the game seems so much simple, it's hard to find a winning strategy. The chances of winning are random and based on pure luck.

                            However, you can try a few things to increase your chances of ensuring more wins.
                        </p>
                        <h3> Bankroll Management Strategy</h3>
                        <p>To keep playing the Dragon Tiger game for longer, players should devise a bankroll management strategy. This means placing small bets and only betting the money you can stand to lose. These strategies will ensure you get more out of your bankroll.
                        </p>
                        <h3> Reduce tries & reduce losses</h3>
                        <p>Never overdo when you are losing. Since the game moves quite rapidly, it's always smart to play only a few games in order to minimise losses.
                        </p>
                        <h3> Use Martingale or other strategies</h3>
                        <p>There are other tips that have mixed results, sticking to one side of a dragon or tiger, avoiding betting on the tie, checking the winning trend, and avoiding a pattern while betting could prove profitable for players.
                        </p>
                        <h3> What makes {domain} the best site to play the Dragon Tiger Game?</h3>

                        <p>There are various reasons that make <a href={`${domainURL}`}>{domain}</a> the best site to play Dragon vs Tiger game. Here are some of the top reasons:
                        </p>
                        <h3> Easy Interface</h3>

                        <p>{domain} offers a live dragon tiger game with a user-friendly interface. There is no chance for users to get confused with the flow or process.
                        </p>
                        <h3> 24x7 Customer Support</h3>

                        <p>Our customer support is available 24/7, 365 days a year, and we have a valid gaming licence.
                        </p>
                        <h3> Rewarding Experience</h3>

                        <p>We offer a <a href={`${domainURL}/promotions/welcome-bonus`}>400% welcome bonus</a> to all new players. You can also enjoy other casino bonuses like loyalty programs and special events. For existing players, there are a variety of referral, cashback, and refill bonuses to select from.

                            {domain} is a great online casino choice for Indian players!
                        </p>
                        <h3> How to Play Dragon vs Tiger game?</h3>

                        <p>The Dragon vs Tiger game is played with just 2 cards: Dragon and Tiger. Go through what it feels like to play the Dragon vs Tiger game.
                        </p>
                        <ul>
                            <li>The players have to choose the card with the highest ranking.<br></br>
                            </li>
                            <li>                      They can also bet on the possibility of a tie.<br></br>
                            </li><li>Here the players do not compete against the dealer but with other players.<br></br></li>
                            <li>The table is covered with a green rug, which is imitated impeccably in online live games.<br></br></li>
                            <li>There are two squares on the table, one for the dragon and one for the tiger.<br></br></li>
                            <li>The game background always has two statues in gold and glass, showing dragons with each on either side.<br></br></li>
                            <li>The rules are really simple that even new players can master the game within minutes. The Dragon vs Tiger game is played with 6 decks of cards without the joker cards.<br></br>
                            </li>
                            <li>Ace has the lowest value of 1, and the king is the highest card.<br></br></li>
                            <li>Cards from 2 to 10 are worth their card values with J being 11, Q equal to 12, and K is worth 13 points.<br></br></li>
                            <li>The players also have other side betting options, which make up around 4 bets.<br></br></li>
                        </ul>
                        <h3> Where to start?</h3>

                        <p>To play the Dragon Tiger Game for real cash, a player must register on {domain}. This process to open a casino account requires a few basic details. Once confirmed, the players can add funds through their preferred payment gateway and get a welcome bonus.
                        </p>
                        <p>Choose the game that suits your needs and budget. Try the demo version if you are confused.

                            The best Dragon vs Tiger game is now available for betting with real money. To earn more profits, follow the tips and strategies. The app is now available to download.
                        </p>
                        <h3> Interesting facts to kickstart your journey</h3>
                        <p>
                            While we are here, let’s understand the game even better through these fun and interesting facts.

                            The rules in online and land-based casinos are the same.
                            There aren’t any winning strategies for dragon tiger game.
                            Dragon vs Tiger is known to be the easiest casino game.
                            It is believed to be the oldest game of chance worldwide.
                            The game first originated in Chinese gambling houses of Macau.
                        </p>
                        <p>
                            <strong>Play Casino Games Now : </strong>
                            <a href={`${domainURL}/online-casino-games/live-roulette`}>Roulette</a>,&nbsp;
                            <a href={`${domainURL}/online-casino-games/live-blackjack`}>Blackjack</a>,&nbsp;
                            <a href={`${domainURL}/online-casino-games/live-baccarat`}>Baccarat</a>,&nbsp;
                            <a href={`${domainURL}/online-casino-games/dragon-tiger`}>Dragon-tiger</a>
                        </p>

                        <p>
                            <strong>Play instant casino Now : </strong>
                            <a href={`${domainURL}/instant-games/aviator`}>Aviator</a>,&nbsp;
                            <a href={`${domainURL}/instant-games/jetx`}>JetX</a>,&nbsp;
                            <a href={`${domainURL}/instant-games/plinko-x`}>PlinkoX</a>,&nbsp;
                            <a href={`${domainURL}/instant-games/mines`}>Mines</a>,&nbsp;
                            <a href={`${domainURL}/instant-games/ninja-crash`}>Ninja Crash</a>,&nbsp;
                            <a href={`${domainURL}/instant-games/paper-plane`}>Paperplane</a>,&nbsp;
                            <a href={`${domainURL}/instant-games/cash-show`}>Cash Show</a>
                        </p>

                        <p>
                            <strong>Browse More casino Now : </strong>
                            <a href={`${domainURL}/online-casino-games/`}>Online Casino Games</a>,&nbsp;
                            <a href={`${domainURL}/online-casino-games/slotGames`}>Slot & Bingo casino</a>,&nbsp;
                            <a href={`${domainURL}/online-casino-games/best-casino-games`}>Popular Casino</a>
                        </p>
                    </div>
                    )}
                </Accordion>
            </AccordionWrapper>
            }
        </>
    )
}

export default withAppContext(DragontigerSEO)